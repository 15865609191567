
import { defineComponent, reactive, ref, watch } from "vue";
import { getCoupons } from "@/core/services/api/coupons";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  components: {
    Datatable,
  },
  props: ["couponBindData", "currentItem"],
  setup(props) {
    const tableData: any = reactive([]);
    const tableHeader = ref([
      { key: "title", name: "優惠標題" },
      { key: "program", name: "優惠模式" },
      { key: "mode", name: "優惠折扣" },
      { key: "beginTime", name: "開始時間" },
      { key: "endTime", name: "結束時間" },
    ]);
    const couponModeDictionary = {
      Amount: "金額",
      Percent: "折數",
    };
    const isLoadind = ref(false);

    const getDiscount = (row) => {
      if (row.mode === "Amount") {
        return row.discount;
      } else {
        const percent = row.discount * 10;
        if (percent === 0) {
          return "全折底";
        } else {
          return percent + "折";
        }
      }
    };

    const setTableData = () => {
      isLoadind.value = true;
      tableData.splice(0);
      for (const o of props.couponBindData) {
        tableData.push({
          title: o.title,
          program: couponModeDictionary[o.mode],
          mode: getDiscount(o),
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
        });
      }
      isLoadind.value = false;
    };

    watch(
      props,
      () => {
        setTableData();
      },
      { deep: true }
    );

    return {
      tableData,
      tableHeader,
      isLoadind,
    };
  },
});

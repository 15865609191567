<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <div class="card-title d-flex justify-content-between">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="tableSearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#bindingCode-general-modal"
        >
          綁定代碼
        </button> -->
        <button
          type="button"
          class="btn btn-primary"
          @click="dialogVisible = true"
        >
          綁定代碼
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-code="{ row: tableData }">
          {{ tableData.code }}
        </template>
        <template v-slot:cell-beginTime="{ row: tableData }">
          {{ tableData.beginTime }}
        </template>
        <template v-slot:cell-endTime="{ row: tableData }">
          {{ tableData.endTime }}
        </template>
        <template v-slot:cell-remark="{ row: tableData }">
          {{ tableData.remark }}
        </template>
        <template v-slot:cell-createTime="{ row: tableData }">
          {{ tableData.createTime }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentItem(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#showOffers-general-modal"
                  >顯示優惠</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 顯示優惠modal -->
  <div
    class="modal fade"
    id="showOffers-general-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="showOffers-general-exampleModalLabel">
            顯示優惠
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ShowOffers
            :couponBindData="currentCoupons"
            :currentItem="currentItem"
          ></ShowOffers>
        </div>
      </div>
    </div>
  </div>

  <!-- 綁定代碼modal -->

 <div >
    <div
      class="modal fade"
      id="bindingCode-general-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style=" background: rgb(59, 53, 80);"
      
    >
      <div class="modal-dialog modal modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="bindingCode-general-exampleModalLabel">
              綁定代碼
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <BindingCode
              @onAddCouponBindCodes="onAddCouponBindCodes()"
            ></BindingCode>
          </div>
        </div>
      </div>
    </div>
  </div> 
  
    <BindingCode  v-model="dialogVisible" @onAddCouponBindCodes="onAddCouponBindCodes()"></BindingCode>

</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getCouponBindCodesBySpace } from "@/core/services/api/couponBindCodes";
import { getCouponsBySpace } from "@/core/services/api/coupons";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import ShowOffers from "../ShowOffers.vue";
import BindingCode from "../BindingCode.vue";
import { Modal } from "bootstrap";
import { PartnerType } from "@/core/services/responsesModel/couponBindCodeBasic";

export default defineComponent({
  components: {
    Datatable,
    ShowOffers,
    BindingCode,
  },
  emits: ["onAddCouponBindCodes"],
  setup() {
    const dialogVisible = ref(false);

    const coupons: any = reactive([]);
    let bindingCodeModal;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const search = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      { key: "code", name: "綁定代碼" },
      { key: "beginTime", name: "起始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "remark", name: "備註", sortable: true },
      { key: "createTime", name: "建立時間", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    const isLoadind = ref(false);
    const currentCoupons: any = reactive([]);
    const currentItem: any = reactive([]);
    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const setTableData = async () => {
      isLoadind.value = true;
      const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
      let couponBindCodes = await getCouponBindCodesBySpace(currentSpaceGuid);
      couponBindCodes = couponBindCodes.filter(
        (o) => o.partner == PartnerType.None
      );
      console.log("couponBindCodes", couponBindCodes);
      tableData.splice(0);
      for (const o of couponBindCodes) {
        tableData.push({
          couponBindCode: o,
          code: o.code,
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
          remark: o.remark,
          createTime: getLocalTimeString(o.createTime),
        });
      }
      initTableData.splice(0, initTableData.length, ...tableData);
      isLoadind.value = false;
    };

    const setCoupons = async () => {
      let request = {
        space: currentSpaceGuid,
      };
      coupons.splice(0, coupons.length, ...(await getCouponsBySpace(request)));
    };

    const setCurrentItem = (item) => {
      let coupon = coupons.filter((o) =>
        item.couponBindCode.coupons.includes(o.guid)
      );
      currentCoupons.splice(0, currentCoupons.length, ...coupon);
      Object.assign(currentItem, item);
    };

    const onAddCouponBindCodes = () => {
      setTableData();
      bindingCodeModal.hide();
    };

    onMounted(() => {
      bindingCodeModal = new Modal(
        document.getElementById("bindingCode-general-modal")
      );
    });

    const init = async () => {
      await setCoupons();
      await setTableData();
    };
    init();

    return {
      search,
      tableData,
      initTableData,
      tableHeader,
      isLoadind,
      tableSearch,
      setCurrentItem,
      currentCoupons,
      currentItem,
      onAddCouponBindCodes,
      dialogVisible,
    };
  },
});
</script>

<style>
</style>
<template>
  <div class="card p-5" style="height: 1000px">
    <div class="card-header border-0">
      <div class="card-toolbar m-0">
        <ul
          class="
            nav nav-stretch
            fs-5
            fw-bold
            nav-line-tabs nav-line-tabs-2x
            border-transparent
          "
          role="tablist"
        >
          <!-- 一般 -->
          <li class="nav-item" role="presentation">
            <a
              id="kt_referrals_year_tab"
              class="nav-link text-active-primary active"
              data-bs-toggle="tab"
              role="tab"
              href="#kt_customer_details_invoices_1"
            >
              一般
            </a>
          </li>
          <!-- 第三方 -->
          <li class="nav-item" role="presentation">
            <a
              id="kt_referrals_year_tab"
              class="nav-link text-active-primary"
              data-bs-toggle="tab"
              role="tab"
              href="#kt_customer_details_invoices_2"
            >
              第三方
            </a>
          </li>
        </ul>
      </div>
      
    </div>

    <div class="card-body pt-0">
      <div id="kt_referred_users_tab_content" class="tab-content">
        <div
          id="kt_customer_details_invoices_1"
          class="py-0 tab-pane fade active show"
          role="tabpanel"
        >
          <General></General>
        </div>
        <div
          id="kt_customer_details_invoices_2"
          class="py-0 tab-pane fade"
          role="tabpanel"
        >
          <ThirdParty></ThirdParty>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import General from "./components/couponBindCode/General.vue"
import ThirdParty from "./components/couponBindCode/ThirdParty.vue"

export default defineComponent({
  components:{
    General,
    ThirdParty
  }
});
</script>

<style>
</style>

import { defineComponent } from "vue";
import General from "./components/couponBindCode/General.vue"
import ThirdParty from "./components/couponBindCode/ThirdParty.vue"

export default defineComponent({
  components:{
    General,
    ThirdParty
  }
});

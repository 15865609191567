
import { defineComponent, reactive, ref, computed } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getCouponsBySpace } from "@/core/services/api/coupons";
import { addCouponBindCodes } from "@/core/services/api/couponBindCodes";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { ConfirmCanncelAlert, SuccesAlert, ErrorAlert } from "@/core/utils/customSwal"
export default defineComponent({
  props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
  setup(props, { emit }) {
      const dialogShow = computed({
            get: () => props.modelValue,
            set: (val) => {
                emit("update:modelValue", val);
            },
        });
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let coupon: any;
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      code: [
        {
          required: true,
          message: "請填寫代碼",
          trigger: "blur",
        },
      ],
      datePicker: [
        {
          required: true,
          message: "請填選時間",
          trigger: "blur",
        },
      ],
      coupon: [
        {
          required: true,
          message: "請填選優惠券",
          trigger: "change",
        },
      ],
    });
    const form = reactive({
      code: "",
      datePicker: [],
      once: false,
      coupon: "",
      remark: ""
    });
    let couponOptions: any = reactive([]);

    const reseatForm = () => {
      Object.assign(form, {
        code: "",
        datePicker: [],
        once: false,
        coupon: "",
        remark:""
      });
    };

    const setCoupon = async () => {
      let request = {
        space: currentSpaceGuid,
      };
      coupon = await getCouponsBySpace(request);
    };

    const setCouponOptions = async () => {
      let filterCoupon = JSON.parse(JSON.stringify(coupon));
      //去除優惠標題為空的
      filterCoupon = filterCoupon.filter((o) => o.title !== "");
      //過濾出選擇的可/否重複使用
      filterCoupon = filterCoupon.filter((o) => o.once == form.once);
      //取出所有優惠的title
      let couponTitle = filterCoupon.map((o) => o.title);
      //去除重複
      couponTitle = Array.from(new Set(couponTitle));
      //賦予下拉選擇框的選項
      couponOptions.splice(0, couponOptions.length, ...couponTitle);
    };

    const submit = () => {
      console.log("submit");
      if (!formRef.value) {
        console.log("fail");
        return;
         
      }
      formRef.value!.validate(async (valid) => {
        
        if (valid) {
          console.log("valid");
          ConfirmCanncelAlert("確定要新增嗎?","確定","取消","若要刪除或修改，請聯繫服務商").then(async (result) => {
            if (result.isConfirmed) {
              let couponGuid = coupon.filter((o) => o.title == form.coupon)[0]
                .guid;
              let request = {
                code: form.code,
                targetType: "Space",
                target: currentSpaceGuid,
                beginTime: getUtcTimeString(form.datePicker[0]),
                endTime: getUtcTimeString(form.datePicker[1]),
                coupons: [couponGuid],
                remark: form.remark
              };
              await addCouponBindCodes(request);
              SuccesAlert("新增成功!");
              emit("onAddCouponBindCodes");
              reseatForm();
            }
          });
        }
      });
    };

    const init = async () => {
      await setCoupon();
      setCouponOptions();
    };
    init();

    return {
      form,
      couponOptions,
      submit,
      formRef,
      rules,
      setCouponOptions,
      dialogShow 
    };
  },
});

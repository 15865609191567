export enum BindTargetType {
    Space = "Space", Alliance = "Alliance"
}

export enum PartnerType {
    None="None", Metro="Metro", Buxiban="Buxiban"
}

export interface CouponBindCodeBasic {
    "guid": string,
    "code": string,
    "targetType": BindTargetType,
    "target": string,
    "partner": PartnerType,
    "coupons": Array<string>,
    "beginTime": string,
    "endTime": string,
    "createTime": string,
    "remark": string
}
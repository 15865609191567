
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getCouponBindCodesBySpace } from "@/core/services/api/couponBindCodes";
import { getCouponsBySpace } from "@/core/services/api/coupons";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import ShowOffers from "../ShowOffers.vue";
import BindingCode from "../BindingCode.vue";
import { Modal } from "bootstrap";
import { PartnerType } from "@/core/services/responsesModel/couponBindCodeBasic";

export default defineComponent({
  components: {
    Datatable,
    ShowOffers,
    BindingCode,
  },
  emits: ["onAddCouponBindCodes"],
  setup() {
    const dialogVisible = ref(false);

    const coupons: any = reactive([]);
    let bindingCodeModal;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const search = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      { key: "code", name: "綁定代碼" },
      { key: "beginTime", name: "起始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "remark", name: "備註", sortable: true },
      { key: "createTime", name: "建立時間", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    const isLoadind = ref(false);
    const currentCoupons: any = reactive([]);
    const currentItem: any = reactive([]);
    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const setTableData = async () => {
      isLoadind.value = true;
      const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
      let couponBindCodes = await getCouponBindCodesBySpace(currentSpaceGuid);
      couponBindCodes = couponBindCodes.filter(
        (o) => o.partner == PartnerType.None
      );
      console.log("couponBindCodes", couponBindCodes);
      tableData.splice(0);
      for (const o of couponBindCodes) {
        tableData.push({
          couponBindCode: o,
          code: o.code,
          beginTime: getLocalTimeString(o.beginTime),
          endTime: getLocalTimeString(o.endTime),
          remark: o.remark,
          createTime: getLocalTimeString(o.createTime),
        });
      }
      initTableData.splice(0, initTableData.length, ...tableData);
      isLoadind.value = false;
    };

    const setCoupons = async () => {
      let request = {
        space: currentSpaceGuid,
      };
      coupons.splice(0, coupons.length, ...(await getCouponsBySpace(request)));
    };

    const setCurrentItem = (item) => {
      let coupon = coupons.filter((o) =>
        item.couponBindCode.coupons.includes(o.guid)
      );
      currentCoupons.splice(0, currentCoupons.length, ...coupon);
      Object.assign(currentItem, item);
    };

    const onAddCouponBindCodes = () => {
      setTableData();
      bindingCodeModal.hide();
    };

    onMounted(() => {
      bindingCodeModal = new Modal(
        document.getElementById("bindingCode-general-modal")
      );
    });

    const init = async () => {
      await setCoupons();
      await setTableData();
    };
    init();

    return {
      search,
      tableData,
      initTableData,
      tableHeader,
      isLoadind,
      tableSearch,
      setCurrentItem,
      currentCoupons,
      currentItem,
      onAddCouponBindCodes,
      dialogVisible,
    };
  },
});

import ApiService from "@/core/services/ApiService";
import { CouponBindCodeBasic } from "@/core/services/responsesModel/couponBindCodeBasic"

// 取得空間的綁定碼資訊
export function getCouponBindCodesBySpace(spaceGuid): Promise<Array<CouponBindCodeBasic>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/CouponBindCodes`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增代碼綁定 一個代碼綁定多個優惠卷
export function addCouponBindCodes(request){
    return new Promise((resolve, reject) => {
        ApiService.post(`CouponBindCodes/Add`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}